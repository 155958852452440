@import '../../vars.scss';

@keyframes LoginEnter {
  from {
    opacity: 0;
    transform: translateY(15%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.Login {
  animation: LoginEnter 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

  background: $brandWhite;
  width: 580px;
  max-width: 100%;
  padding: 85px $paddingMedium 60px $paddingMedium;
  box-sizing: border-box;
  margin: auto;
  box-shadow: $bigShadow;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;

  /* Mobile */
  @media (max-width: $mobileBreakpoint) {
    width: 100vw;
    height: 100vh;
    padding-top: 72px;
    margin: 0;
  }
  
  @keyframes FormEnter {
    0% {
      opacity: 0;
      max-height: 0;
      padding-top: 0px;
    }
    50% {
      opacity: 0;
      max-height: 300px;
      padding-top: 30px;
    }
    100% {
      opacity: 1;
      max-height: 500px;
      padding-top: 30px;
    }
  }

  .form {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    max-width: 100%;
    
    &.entered {
      padding-top: 30px;
      animation: FormEnter 2000ms ease;
      opacity: 1;
      max-height: 500px;
    }

    width: 280px;
    box-sizing: border-box;

    .ButtonContainer {
      padding-top: 32px;

      & > * {
        margin-right: 12px;
      }
    }
  }

  .Error {
    margin-top: 1em;
    padding: 1em;
    background-color: rgb(255, 238, 238);
  }

  .SignUp {
    button {
      margin-top: 32px;
    }
  }
}