@import '../../vars.scss';

.WeeklyFlavours {
  display: flex;
  flex-direction: column;
  padding: 32px;

  .ActionContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-bottom: 8px;
  }

  .FlavourRow {
    display: flex;
    flex-direction: column;
    border: 1px solid $brandMediumGrey;
    padding: 16px 32px;

    label {
      @include Label();
    }

    pre {
      text-align: left;
      white-space: pre;
    }
  }
}