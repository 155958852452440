$brandPrimary: #ea4c89;
$brandSecondary: #9e4cea;
$brandNotSelected: #af7189;
$brandWhite: #ffffff;
$brandLightGrey: #F8F8F9;
$brandDarkGrey: #414042;
$brandMediumGrey: #8A959E;
$brandHighlightedBlue: rgb(185, 211, 233);
$brandBlue: #2E92E9;
$brandDarkBlue: rgb(25, 84, 136);
$brandYellow: #ffe600;
$brandRed: #FC3868;
$brandGreen: rgb(26, 206, 101);
$brandOrange: rgb(228, 149, 46);
$borderGrey: #A8A8A8;

$mediumLightGrey: #E9E9E9;

$font1: 'Open Sans', sans-serif;
$font2: 'Montserrat', sans-serif;
$font3: 'Lustria', sans-serif;

$bigShadow: 0 8px 40px rgba(0,0,0,0.14);

$padding: 10px;
$paddingMedium: $padding * 2;
$paddingLarge: $padding * 3;
$paddingXLarge: $padding * 4;

@mixin Link() {
  font-family: $font2;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  color: $brandPrimary;

  &.active {
    text-decoration: underline;
  }

  &:hover {
    color: tint($brandPrimary, 20);
  }

  &:active {
    color: shade($brandPrimary, 80);
  }
}

@mixin Label() {
  font-family: $font2;
  color: $brandMediumGrey;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
}

@mixin Card($hoverable: true) {
  background: $brandWhite;
  box-shadow: 0 3px 10px #00000011;
  border-radius: 4px;
  transition: 200ms;
  
  &.deepShadow {
    box-shadow: 0 3px 10px #00000033;
  }
  
  @if $hoverable {
    cursor: pointer;

    &:hover {
      box-shadow: 0 8px 20px #00000033;

      &.deepShadow {
        box-shadow: 0 8px 20px #00000055;
      }
      z-index: 10;
      transform: translateY(-4px);
    }
  }
}

@keyframes ScreenIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@mixin AnimateScreenIn() {
  animation: ScreenIn 200ms ease-out;
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$mobileBreakpoint: 640px;