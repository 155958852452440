@import './vars.scss';
@import url('https://fonts.googleapis.com/css?family=Lustria|Montserrat:300,400,600,700,800,900|Open+Sans:300,400,600,700,800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $brandPrimary;
  
  @media print {
    background-color: white;
  }
}

.App {
  a {
    text-decoration: none;
    color: unset;
    &:hover,
    &:active {
      color: unset;
    }
  }
  
  display: flex;
  flex-direction: column;
  background-color: $brandWhite;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100%;

  * {
    box-sizing: border-box;
  }

  text-align: center;
  font-family: $font1;

  .button {
    display: flex;
    margin: 0;
    background: $brandBlue;
    border: none;
    border-radius: 8px;
    padding: 10px 16px;
    color: white;
    cursor: pointer;
    transition: all 200ms ease;
    appearance: none;
    position: relative;
    outline: none;
    font: 600 13px $font1;
    align-items: center;
    justify-content: center;

    &.active {
      border: 2px solid $brandSecondary;
    }

    &:disabled {
      opacity: 0.25;
    }

    &:hover {
      background: tint($brandBlue, 50);
    }

    &:active {
      background: shade($brandBlue, 10);
    }

    &.success {
      background-color: $brandGreen;

      &:hover {
        background: tint($brandGreen, 50);
      }
      &:active {
        background: shade($brandGreen, 10);
      }
    }

    &.delete {
      background-color: $brandRed;
      &:hover {
        background: tint($brandRed, 50);
      }
      &:active {
        background: shade($brandRed, 10);
      }
    }

    &.secondary {
      background-color: $brandSecondary;

      &:hover {
        background: tint($brandSecondary, 50);
      }
      &:active {
        background: shade($brandSecondary, 10);
      }
    }

    &.notSelected {
      opacity: 0.5;
      background-color: $brandMediumGrey;

      &:hover {
        background: tint($brandMediumGrey, 50);
      }
      &:active {
        background: shade($brandMediumGrey, 10);
      }
    }
  }
}