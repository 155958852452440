@import '../../vars.scss';

.Orders {
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  .ActionContainer {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow-x: hidden;
    flex: 1;
    margin-bottom: 8px;

    .Searching {
      input {
        background-color: tint($brandRed, 80);
      }
    }
  }

  .MacOrdersContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 0;

    .MacOrderRow > .Source,
    .Heading > .Source,
    .MacOrderRow > .Description,
    .Heading > .Description {
      display: none;
    }

    .TextInput.vertical {
      width: unset;
    }

    .ActionContainer {
      display: flex;
      flex-direction: column;
      margin: 0 8px;
    }

    .react-datepicker-wrapper {
      overflow-x: hidden;
    }
  }
}