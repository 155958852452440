@import '../vars.scss';

.TextInput {
  display: flex;

  & + .TextInput {
    margin-top: $padding;
  }

  span {
    @include Label();
    margin-bottom: 4px;
  }
  
  &.horizontal {
    flex-direction: row;
    align-items: center;

    .label {
      flex: 0 0 30%;
      margin: 6px 0 0 0;
    }
    
    .label + input,
    .label + textarea {
      flex: 0 0 70%;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  textarea::placeholder,
  input::placeholder {
    opacity: 0.5;
  }

  textarea,
  input {
    height: 44px;
    box-sizing: border-box;
    border: 1px solid $borderGrey;
    border-width: 0 0 1px 0;
    padding: 0 1ch;
    font-size: 14px;
    transition: 200ms;

    &:focus {
      border-color: $brandBlue;
      outline: none;
    }

    &:disabled {
      color: $brandDarkGrey;
      box-shadow: none;
      background: $brandLightGrey;
      border-color: transparent;
      padding: 0 $padding;
    }
  }

  textarea {
    height: 150px;
    padding: 1ch;
  }

  &.error {
    textarea:not(:focus),
    input:not(:focus) {
      &:not(:disabled) {
        border-color: $brandRed;
      }
      background-color: rgba($brandRed, 0.2);
    }
  }

  &.rounded {
    textarea,
    input {
      border-radius: 100px;
      border-width: 1px;
      padding: 0 $paddingMedium;
    }
  }
}