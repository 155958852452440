@import '../vars.scss';

.CalendarDatePicker {
  font-family: "Open Sans", sans-serif;

  &.react-datepicker {
    border: 1px solid $brandBlue;
    background: $brandWhite;
    filter: drop-shadow(0 2px 4px #0005);
    border-radius: 0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: $brandBlue;
  }

  .react-datepicker__header {
    background: $brandLightGrey;
    border-radius: 0;
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  .CalendarDatePicker {
    .react-datepicker__triangle,
    .react-datepicker__triangle::before {
      border-bottom-color: $brandLightGrey;
    }
    
    .react-datepicker__triangle::before {
      border-bottom-color: $brandBlue;
    }
  }
}