@import '../../vars.scss';

.Account {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  padding: 16px;
  
  .Tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    align-items: center;

    .Field.Tag {
      padding: 4px 8px;
      border-radius: 16px;
      margin-left: 4px;
      color: white;
      font-size: 10px;
      background-color: $brandPrimary;
    }
  }

  .NotesContainer {
    display: flex;
    flex-direction: column;

    .Notes {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .Note {
        box-sizing: border-box;
        width: 50%;

        &:nth-child(odd) {
          width: calc(50% - 20px);
          margin-right: 20px;
        }

        textarea {
          height: 300px;
        }

        label {
          @include Label();
        }
      }
    }
  }

  @media (max-width: $mobileBreakpoint) {
    & {
      display: flex;
      flex-direction: column;
      padding-top: 0;

      h1 {
        margin: 8px 0;
      }
    }

    .Tags {
      .Field.Tag {
        display: none;
      }
    }

    .NotesContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      .Notes {
        display: flex;
        flex-direction: column;
        flex-wrap: unset;
        flex: 1;

        .Note {
          flex: 1;
          width: unset;  
          margin-top: 8px;
          margin-bottom: 8px;
          border: 1px solid $brandMediumGrey;
          padding: 16px;

          &:nth-child(odd) {
            width: unset;
            margin-right: unset;
          }
        }
      }
    }
  }
}