@import '../../vars.scss';

.MacOrderForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;

  .react-datepicker-wrapper {
    width: unset;
  }

  .edited {
    color: $brandMediumGrey;
    font-style: italic;
  }

  & > * {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .DescriptionContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;

    .Order {
      flex: 1;
    }

    .Processed {
      text-align: left;
      width: 250px;
      font-size: 10px;
    }
  }

  label {
    @include Label();
    text-align: left;
  }

  .FormActionGroup {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

    .delete {
      margin-right: auto;
    }
    .cancel {
      margin-right: 4px;
    }
  }

  .ButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 8px;

    .TextInput + .TextInput {
      margin-left: 16px;
      margin-top: 0;
    }

    button {
      margin-right: 4px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    & .ButtonGroup .TextInput + .TextInput {
      margin: 0;
    }

    .ButtonGroup {
      display: flex;
      flex-direction: column;
      width: 100%;

      .TextInput {
        margin: 0;
      }
    }

    .SourceContainer,
    .CustomerTypeContainer {
      display: flex;
      flex-direction: column;

      .ButtonGroup {
        flex-direction: row;
      }
    }

    .PaymentStatusContainer,
    .NextActionContainer {
      margin-top: 16px;

      label {
        margin-bottom: 8px;
      }

      .ButtonGroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        justify-content: flex-start;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(25% - 4px);
          margin-right: 4px;
          margin-bottom: 8px;
        }
      }
    }

    .DescriptionContainer {
      display: flex;
      flex-direction: column;

      textarea {
        height: 300px;
      }

      .Processed {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 16px;

        & > * {
          margin: 0;
        }
      }
    }
  }

  @media print {
    .SourceContainer,
    .CustomerTypeContainer,
    .PaymentStatusContainer,
    .NextActionContainer {
      display: none;
    }
  }
}