@import '../../vars.scss';

.WaveInvoices {
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  
  @media (max-width: $mobileBreakpoint) {
    padding: 8px;
  }

  .InvoicesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: $mobileBreakpoint) {
      width: 100%;
      flex-direction: column;
    }
  }

  .Paginator {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    
    * {
      margin-right: 8px;
    }
  }
}