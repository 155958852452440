.WaveCustomers {
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;

  .CustomersContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Paginator {
    display: flex;
    flex-direction: row;

    margin-bottom: 16px;
    
    * {
      margin-right: 8px;
    }
  }
}