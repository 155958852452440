@import '../vars.scss';

.Header {
  display: flex;
  padding: 4px 16px;
  justify-content: space-between;
  align-items: center;

  @media print {
    & {
      display: none;
    }
  }

  a {
    @include Link();
    color: $brandPrimary;
    text-decoration: none;
  }

  @media (max-width: $mobileBreakpoint) {
    align-items: flex-start;
    flex-wrap: wrap;

    img {
      display: none;
    }

    a {
      margin: 8px 16px;
    }
  }
}