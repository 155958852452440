@import '../../vars.scss';

.Inventory {
  display: flex;
  flex-direction: column;
  padding: 24px;

  .InventoryRow {
    display: flex;
    flex-direction: row;
    padding: 8px;

    .Name {
      text-align: left;
      flex: 1;
    }

    .Actions {
      display: flex;
      justify-content: center;
      
      .Qty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font2;
        font-weight: bold;
        min-width: 40px;
        font-size: 24px;
        padding: 0 8px;
        border-radius: 8px;

        &.red {
          background-color: tint($brandRed, 80);
          color: $brandRed;
        }

        &.orange {
          background-color: tint($brandOrange, 80);
          color: $brandOrange;
        }

        &.green {
          background-color: tint($brandGreen, 80);
          color: shade($brandGreen, 20);
        }
      }
    }

    .button.Edit {
      margin-left: 16px;
    }
  }

  .Modal.EditInventory {
    .TextInput:nth-child(1) {
      margin-top: 32px;
    }

    button.SaveButton {
      margin-top: 16px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    & {
      padding: 4px;

    }

    .button.Edit {
      padding: 0 8px;
    }

    .Modal {
      max-height: 300px;
      width: 300px;
      height: 300px;
    }
  }
}