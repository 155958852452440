@import '../../vars.scss';

.MiniCustomer {
  display: flex;
  flex-direction: column;
  width: 30%;
  border: 1px solid $brandDarkGrey;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
  margin-bottom: 8px;
  margin-right: 8px;

  &.Synced {
    border: 1px solid $brandGreen;
    background: tint($brandGreen, 90);
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
  }

  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Customer {
    display: flex;
    flex-direction: column;
    overflow-wrap: normal;
    width: calc(100% - 80px);
    overflow-wrap: break-word;
    
    span {
      font-family: $font3;
      font-size: 11px;
    }

    span:nth-child(1) {
      font-family: $font2;
      text-transform: uppercase;
      font-weight: 800;    
    }
  }
}