@import '../../vars.scss';

.MacOrderRow,
.Heading {
  display: flex;
  flex: 1;
  font-size: 11px;
  cursor: pointer;

  .Description {
    display: flex;
    flex: 1;
    white-space: pre-wrap;
    text-align: left;
  }

  .CustomerName {
    display: flex;
    text-align: left;
    width: 200px;
    font-weight: bold;
  }

  .PickUpDate,
  .DeliveryDate {
    display: flex;
    width: 150px;
  }

  .tag {
    display: block;
    color: white;
    padding: 4px 8px;
    height: 24px;
    border-radius: 6px;
    background-color: $brandOrange;
    margin-right: 2px;
    margin-bottom: 2px;

    &.follow_up {
      background-color: $brandBlue;
    }
    &.send_invoice {
      background-color: $brandRed;
    }
    &.schedule_fulfillment {
      background-color: $brandPrimary;
    }
    &.bake {
      background-color: $brandSecondary;
    }
    &.pack {
      background-color: $brandGreen;
    }
  }

  .LineItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 220px;

    & > * {
      &:before {
        content: '• ';
      }
    }
  }

  .Source {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 70px;
  }

  .Status {
    display: flex;
    width: 130px;
    flex-direction: row;
    justify-content: flex-start;
  }

  &.Heading {
    @include Label();
    font-weight: bold;

    & > * {
      border-bottom: 0;
    }
    
    &:hover {
      cursor: unset;
      background-color: unset;
    }
  }

  &:hover {
    background: $brandHighlightedBlue;
  }

  & > * {
    padding: 8px 16px;
    display: flex;
    border-bottom: 1px solid $brandMediumGrey;

    @media (max-width: $mobileBreakpoint) {
      padding: 2px 4px;
      align-items: center;
    }
  }

  &.urgent {
    color: white;
    background-color: $brandRed;
    
    .Status {
      border-left: 8px solid $brandRed;
    }
  }

  &.safe .Status {
    border-left: 8px solid white;
  }

  &.green .Status {
    border-left: 8px solid $brandGreen;
  }

  &.amber .Status {
    border-left: 8px solid $brandOrange;
  }

  &.danger .Status {
    border-left: 8px solid $brandRed;
  }
}