@import '../../vars.scss';

.Invoices {
  display: flex;
  flex-direction: column;
    
  .InvoiceRow {
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    text-align: left;
    margin-bottom: 8px;
    border-bottom: 1px solid $brandMediumGrey;
  
    @media (min-width: 768px) {
      align-items: flex-start;
      flex-direction: column;
    }

    & > *:nth-child(1) {
      width: 200px;
    }
    
    & > *:nth-child(2) {
      width: 100px;
    }

    & > *:nth-child(3) {
      flex: 1;
      white-space: pre;
    }
    
    .LineItems {

    }
  }
}