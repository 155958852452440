@import '../vars.scss';

.ModalBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 10;

  .Modal {
    position: relative;
    background: white;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 20px 40px;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: scroll;

    .Close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .Modal {
      overflow-x: hidden;
      max-width: 100vw;
      max-height: 100vh;
      height: 100vh;
      border-radius: unset;
      padding: 16px;
    }
  }
}