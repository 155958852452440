@import '../../vars.scss';

.ShopifyOrders {
  display: flex;
  flex-direction: column;

  .OrdersContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 32px;

    @media (max-width: $mobileBreakpoint) {
      padding: 8px;
    }
  }
}