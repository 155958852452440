@import '../../vars.scss';

.BakeSchedule {
  display: flex;
  flex-direction: column;

  .GroupedContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
    padding: 16px;
    
    .Grouped {
      border: 1px solid $brandHighlightedBlue;
      border-radius: 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      width: 30%;
      display: flex;
      flex-direction: column;
      padding: 8px;

      .Flavour {
        font-family: $font1;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 800;
      }

      p {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
          font-family: $font2;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 10px;
          color: $brandDarkGrey;

          &.Total {
            margin-top: 8px;
          }

          &.Extras {
            font-weight: normal;
            font-style: italic;
          }

          &.classic {
            color: $brandPrimary;
          }
          &.signature {
            color: $brandSecondary;
          }
          &.instock {
            color: $brandDarkBlue;
          }
          &.custom {
            color: $brandGreen;
          }
        }
      }
  
      p,h1,h2,h3,h4 {
        margin: 0;
        padding: 0;
      }


      @media (max-width: $mobileBreakpoint) {
        width: 100%;

        .Flavour {
          font-size: 16px;
        }

        p {
          span {
            font-size: 12px;

            &.Total {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  
  .BakeRow {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 4px 16px;

    .Row {
      flex: 1;
      display: flex;
      flex-direction: row;

      &>span {
        width: 100px;
        padding: 4px;
      }

      &>.Title {
        flex: 1;
        width: unset;
      }

      &.Customers {
        display: flex;
        flex-direction: column;
      }

      &.Totals {
        font-size: 12px;
        color: white;
        background-color: $brandDarkGrey;
      }
      
      &.HeaderRow {
        span {
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;
          color: $brandMediumGrey;
        }
      }
    }

    .Count {
      font-weight: bold;
    }
  }

  .Print {
    display: none;
  }

  @media print {
    .Print {
      display: flex;
    }

    .CustomerNamesTiny {
      flex: 1;
      font-size: 9px;
    }

    h1,
    .SortButtons,
    .Missing,
    button {
      display: none;
    }

    .BakeRow {
      border: 1px solid black;
      // padding-bottom: 64px;

      span.Count {
        width: 50px;
      }
    }

    .HeaderRow {
      padding-bottom: 0;

      .Row {
        & > span {
          width: 50px
        }
      }
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .MacOrderRow {
      .Source,
      .Description {
        display: none;
      }
    }

    .HeaderRow,
    .Totals,
    .BakeRow {
      .Row .ColumnBatchesExtra,
      .Row .ColumnMacaronsExtra {
        width: 50px;
      }
      .ColumnExtras,
      .ColumnTotalOrders,
      .ColumnBatches,
      .ColumnMacarons {
        display: none;
      }
    }
  }
}