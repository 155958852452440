@import '../../vars.scss';

.MiniInvoice {
  display: flex;
  flex-direction: column;
  width: 30%;
  border: 1px solid $brandDarkGrey;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
  margin-bottom: 8px;
  margin-right: 8px;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
  }

  &.Paid {
    background-color: tint($brandGreen, 90);
  }

  // &.Unpaid {
  //   background-color: tint($brandRed, 80);
  // }

  &.PartialPaid {
    background-color: tint($brandOrange, 80);
  }

  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Customer {
    display: flex;
    flex-direction: column;
    overflow-wrap: normal;
    width: calc(100% - 80px);
    overflow-wrap: break-word;

    span:nth-child(1) {
      font-family: $font2;
      text-transform: uppercase;
      font-weight: 800;    
    }
    span:nth-child(2) {
      font-family: $font3;
      font-size: 11px;
    }
  }

  .InvoiceDate {
    display: flex;
    flex-direction: column;
    text-align: right;
    min-width: 80px;

    span:nth-child(1) {
      &::before {
        content: '#';
      }

      font-family: $font2;
      text-transform: uppercase;
      font-weight: 800;
    }

    span:nth-child(2) {
      font-family: $font3;
      font-size: 11px;
    }
  }

  .LineItems {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    font-family: $font1;
    font-size: 10px;

    .LineItem {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      white-space: pre-wrap;

      .SubTotal {
        display: flex;
        flex-direction: row;
  
        *:nth-child(1) {
          flex: 1;
        }
  
        *:nth-child(2) {
          &::after {
            content: 'x';
          }
          width: 20px;
        }
  
        *:nth-child(3) {
          &::before {
            content: '$';
          }
  
          width: 20px;
        }
      }
    }
  }

  .AmountDue {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    
    span:nth-child(1) {
      &::before {
        content: 'Total $';
      }

      font-family: $font2;
      text-transform: uppercase;
      font-weight: 800;
    }
    span:nth-child(2) {
      &::before {
        content: 'Paid $';
      }

      font-family: $font2;
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  .Memo {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    font-family: $font1;
    font-size: 10px;
    font-style: italic;
    white-space: pre-wrap;
  }

}