@import '../../vars.scss';

.ShopifyOrderRow {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30%;
  border: 1px solid $brandBlue;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
  padding: 16px;
  
  @media (max-width: $mobileBreakpoint) {
    & {
      width: 100%;
    }
  }

  .OrderNumberCustomer {
    display: flex;
    flex-direction: column;

    .OrderNumber {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: bold;
    }

    & > span {
      margin-top: 4px;
    }
    .OrderDate {
      font-size: 9px;
      font-weight: bold;
    }
    .Name {
      font-family: $font2;
      text-transform: uppercase;
    }
    .Email {
      font-size: 12px;
    }
    .Phone {
      font-weight: bold;
    }
    .Total {
      font-weight: bold;
    }
    .Shipping {
      font-family: $font2;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .LineItems {
    text-align: left;
    font-size: 12px;
    margin-top: 10px;

    p {
      padding: 2px;
      border-bottom: 1px solid $brandMediumGrey;

      &:hover {
        background-color: $brandRed;
        color: white;
        font-weight: bold;
      }

      strong {
        font-size: 14px;
        color: $brandRed;
        margin-right: 8px;

        &::after {
          content: 'x ';
        }
      }
    }
  }
}